<template>
  <div id="wrapper">
    <div id="componentLibrary" class="component-library" v-loading="getTablesLoading">
      <div v-for="item in tables" :key="item.id" :data-id="item.id" class="library-item" draggable="true">
        {{ item.expandTableName }}
      </div>
    </div>
    <div v-loading="loading" id="diagramContainer" class="diagram-container">
      <!-- This is the canvas where items will be dropped and made interactive -->
    </div>
    <el-button type="info" size="small" @click="$router.back()" class="back-button">返回</el-button>
    <el-button type="primary" size="small" @click="saveDiagram" class="save-button">保存</el-button>
    <el-dialog
      title="流向名称"
      :visible.sync="inputNameDialog"
      width="300px">
      <el-input v-model="flowName" size="" placeholder="请输入数据流向的名称"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="inputNameDialog = false">取消</el-button>
        <el-button size="small" type="primary" @click="saveToDB">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="关系配置"
      :visible.sync="dialogVisible"
      width="500px">
      <div>
        <div style="display: grid; grid-template-columns: auto auto 30px; column-gap: 10px;">
          <p>来源表: <span style="color: #3472c7; font-weight: bold">{{ sourceItem.expandTableName }}</span></p>
          <p>目标表: <span style="color: #3472c7; font-weight: bold">{{ targetItem.expandTableName }}</span></p>
        </div>
        <el-tabs type="border-card">
          <el-tab-pane style="padding: 10px" v-loading="getFieldsLoading">
            <span slot="label">表单转换</span>
            <div style="display: grid; grid-template-columns: auto 100px auto 20px; column-gap: 10px; margin-bottom: 10px" v-for="rule in convertRule">
              <div>
                <el-select filterable size="small" style="width: 100%" v-model="rule.source" placeholder="请选择">
                  <el-option v-for="item in sourceFields" :value="item.fieldCode" :key="item.fieldCode" :label="item.fieldDisplay"></el-option>
                </el-select>
              </div>
              <div>
                <el-select filterable size="small" style="width: 100%" v-model="rule.type" placeholder="请选择">
                  <el-option value="zdys" label="字段映射"></el-option>
                  <el-option disabled value="公式规则"></el-option>
                </el-select>
              </div>
              <div>
                <el-select filterable size="small" style="width: 100%" v-model="rule.target" placeholder="请选择">
                  <el-option v-for="item in targetFields" :value="item.fieldCode" :key="item.fieldCode" :label="item.fieldDisplay"></el-option>
                </el-select>
              </div>
              <div>
                <el-button @click="removeConvertRule(rule)" size="mini" type="danger" icon="el-icon-minus" circle></el-button>
              </div>
            </div>
            <div style="text-align: right; margin-right: -9px">
              <el-button @click="addConvertRule" size="mini" type="success" icon="el-icon-plus" circle></el-button>
            </div>
          </el-tab-pane>
          <el-tab-pane label="审批管理">
            <div>
              <el-switch
                style="display: block"
                v-model="convertAudit.enable"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="开启审批"
                inactive-text="无需审批">
              </el-switch>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="confirmSettings">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { jsPlumb } from 'jsplumb'
import { expandTablesList } from '@/api/expandTable'
import { getFields } from '@/api/expandTableFields'
import { addFlow, detail } from '@/api/expandTableFlow'

export default {
  name: 'landing-page',
  data () {
    return {
      loading: false,
      getTablesLoading: false,
      getFieldsLoading: false,
      tables: [],
      dialogVisible: false,
      inputNameDialog: false,
      flowName: '',
      sourceItem: {},
      targetItem: {},
      sourceFields: [],
      targetFields: [],
      convert: {},
      convertAudit: {
        enable: false
      },
      convertRule: [
        {
          type: 'zdys',
          source: '',
          target: ''
        }
      ]
    }
  },
  async mounted () {
    this.clearCanvas()
    await this.expandTablesList()
    jsPlumb.ready(() => {
      jsPlumb.setContainer('diagramContainer')
      const common = {
        isSource: true,
        isTarget: true,
        connector: ['Flowchart', { stub: [40, 60], gap: 10, cornerRadius: 5, alwaysRespectStubs: true }],
        endpoint: 'Dot',
        connectorStyle: { stroke: '#3A404E', strokeWidth: 1, outlineStroke: 'transparent', outlineWidth: 4 },
        connectorOverlays: [
          ['Arrow', {
            location: 1,
            width: 10,
            length: 10,
            foldback: 1
          }],
          ['Custom', {
            create: function (component) {
              var div = document.createElement('div')
              div.innerHTML = '<button type="button" class="el-button el-button--success el-button--mini"><!----><!----><span>设置</span></button>'
              return div
            },
            location: 0.5,
            id: 'settings',
            cssClass: 'settings-overlay'
          }]
        ]
      }

      const initDraggable = (elementId) => {
        jsPlumb.addEndpoint(elementId, { anchors: 'Right' }, common)
        jsPlumb.addEndpoint(elementId, { anchors: 'Bottom' }, common)
        jsPlumb.draggable(elementId, { containment: 'parent' })
      }

      jsPlumb.bind('connection', (info, originalEvent) => {
        if (info.sourceId === info.targetId) {
          jsPlumb.deleteConnection(info.connection)
          this.$message.error('不能连接到自己')
        }
      })

      jsPlumb.bind('click', (connection, originalEvent) => {
        console.log(connection)
        const sourceId = connection.sourceId
        const targetId = connection.targetId
        const sourceItem = this.tables.find(table => `table-${table.id}` === sourceId)
        const targetItem = this.tables.find(table => `table-${table.id}` === targetId)
        this.openDialog(sourceItem, targetItem)
      })

      document.querySelectorAll('.library-item').forEach(item => {
        item.addEventListener('dragstart', (e) => {
          const itemId = item.getAttribute('data-id')
          e.dataTransfer.setData('text/plain', itemId)
        })
      })

      document.getElementById('diagramContainer').addEventListener('drop', (e) => {
        e.preventDefault()
        const itemId = e.dataTransfer.getData('text/plain')
        const table = this.tables.find(table => table.id === itemId)

        if (table) {
          const newItem = document.createElement('div')
          newItem.className = 'state-item'
          newItem.textContent = table.expandTableName
          newItem.style.position = 'absolute'
          newItem.style.left = `${e.offsetX - 40}px`
          newItem.style.top = `${e.offsetY - 40}px`
          newItem.id = `table-${table.id}`
          document.getElementById('diagramContainer').appendChild(newItem)
          initDraggable(newItem.id)
        }
      })

      document.getElementById('diagramContainer').addEventListener('dragover', (e) => {
        e.preventDefault()
      })

      if (this.$route.meta.type === 'edit') {
        this.getCanvas()
      }
    })
  },
  methods: {
    async getCanvas () {
      const res = await detail({ id: this.$route.params.id })
      const diagramData = { items: res.data.items, connections: res.data.connections }
      this.loadDiagram(diagramData)
      this.convert = res.data.convertMap
      this.flowName = res.data.flowName
      this.loading = false
    },
    addConvertRule () {
      this.convert[this.sourceItem.id + '->' + this.targetItem.id].convertRule.push({
        type: 'zdys',
        source: '',
        target: ''
      })
    },
    removeConvertRule (rule) {
      const index = this.convert[this.sourceItem.id + '->' + this.targetItem.id].convertRule.indexOf(rule)
      if (index !== -1) {
        this.convert[this.sourceItem.id + '->' + this.targetItem.id].convertRule.splice(index, 1)
      }
    },
    async getFields () {
      this.getFieldsLoading = true
      const res1 = await getFields({ tableId: this.sourceItem.id })
      const res2 = await getFields({ tableId: this.targetItem.id })
      this.getFieldsLoading = false
      this.sourceFields = res1.data
      this.targetFields = res2.data
    },
    async expandTablesList () {
      this.getTablesLoading = true
      const res = await expandTablesList({})
      this.getTablesLoading = false
      this.tables = res.data
    },
    clearCanvas () {
      // Remove all elements from the canvas
      const diagramContainer = document.getElementById('diagramContainer');
      while (diagramContainer.firstChild) {
        diagramContainer.removeChild(diagramContainer.firstChild);
      }
      // Clear all jsPlumb connections
      jsPlumb.reset()
    },
    async saveDiagram () {
      const items = []
      document.querySelectorAll('.state-item').forEach(item => {
        items.push({
          id: item.id,
          text: item.textContent,
          left: item.style.left,
          top: item.style.top
        })
      })

      const connections = []
      jsPlumb.getAllConnections().forEach(conn => {
        connections.push({
          sourceId: conn.sourceId,
          targetId: conn.targetId
        })
      })

      // const diagramData = { items, connections }
      // localStorage.setItem('diagramData', JSON.stringify(diagramData))
      // localStorage.setItem('convert', JSON.stringify(this.convert))
      // this.$message.success('已保存')
      await this.saveOrUpdate()
    },
    async saveOrUpdate () {
      if (this.$route.meta.type === 'add') {
        this.flowName = ''
        this.inputNameDialog = true
      } else {
        this.saveToDB()
      }
    },
    async saveToDB () {
      const items = []
      document.querySelectorAll('.state-item').forEach(item => {
        items.push({
          id: item.id,
          text: item.textContent,
          left: item.style.left,
          top: item.style.top
        })
      })
      const connections = []
      jsPlumb.getAllConnections().forEach(conn => {
        connections.push({
          sourceId: conn.sourceId,
          targetId: conn.targetId
        })
      })
      const res = await addFlow({
        connections: connections,
        items: items,
        convertMap: this.convert,
        flowName: this.flowName,
        canvasId: this.$route.params.id
      })
      if (res.code === 0) {
        this.inputNameDialog = false
        this.$message.success(res.msg)
        this.$router.back()
      } else {
        this.$message.error(res.msg)
      }
    },
    loadDiagram (diagramData) {
      // const diagramData = JSON.parse(localStorage.getItem('diagramData'))
      if (diagramData) {
        diagramData.items.forEach(itemData => {
          const newItem = document.createElement('div')
          newItem.className = 'state-item'
          newItem.textContent = itemData.text
          newItem.style.position = 'absolute'
          newItem.style.left = itemData.left
          newItem.style.top = itemData.top
          newItem.id = itemData.id
          document.getElementById('diagramContainer').appendChild(newItem)
          this.initDraggable(newItem.id)
        })

        setTimeout(() => {
          diagramData.connections.forEach(conn => {
            jsPlumb.connect({
              source: conn.sourceId,
              target: conn.targetId,
              connector: ['Flowchart', { stub: [40, 60], gap: 10, cornerRadius: 5, alwaysRespectStubs: true }],
              endpoint: 'Dot',
              paintStyle: { stroke: '#3A404E', strokeWidth: 1, outlineStroke: 'transparent', outlineWidth: 4 },
              anchors: ['Right', 'Bottom'],
              overlays: [
                ['Arrow', {
                  location: 1,
                  width: 10,
                  length: 10,
                  foldback: 1
                }],
                ['Custom', {
                  create: function (component) {
                    var div = document.createElement('div')
                    div.innerHTML = '<button type="button" class="el-button el-button--success el-button--mini"><!----><!----><span>设置</span></button>'
                    return div
                  },
                  location: 0.5,
                  id: 'settings',
                  cssClass: 'settings-overlay'
                }]
              ]
            })
          })
        }, 50) // Delay to ensure all elements are ready
      }
    },
    initDraggable (elementId) {
      const common = {
        isSource: true,
        isTarget: true,
        connector: ['Flowchart', { stub: [40, 60], gap: 10, cornerRadius: 5, alwaysRespectStubs: true }],
        endpoint: 'Dot',
        connectorStyle: { stroke: '#3A404E', strokeWidth: 1, outlineStroke: 'transparent', outlineWidth: 4 },
        connectorOverlays: [
          ['Arrow', {
            location: 1,
            width: 10,
            length: 10,
            foldback: 1
          }],
          ['Custom', {
            create: function (component) {
              var div = document.createElement('div')
              div.innerHTML = '<button type="button" class="el-button el-button--success el-button--mini"><!----><!----><span>设置</span></button>'
              return div
            },
            location: 0.5,
            id: 'settings',
            cssClass: 'settings-overlay'
          }]
        ]
      }

      jsPlumb.addEndpoint(elementId, { anchors: 'Right' }, common)
      jsPlumb.addEndpoint(elementId, { anchors: 'Bottom' }, common)
      jsPlumb.draggable(elementId, { containment: 'parent' })
    },
    openDialog (sourceItem, targetItem) {
      this.sourceItem = sourceItem
      this.targetItem = targetItem
      if (!this.convert[sourceItem.id + '->' + targetItem.id]) {
        this.convert[sourceItem.id + '->' + targetItem.id] = {
          convertAudit: {
            enable: false
          },
          convertRule: [
            {
              type: 'zdys',
              source: '',
              target: ''
            }
          ]
        }
      }
      this.convertRule = this.convert[sourceItem.id + '->' + targetItem.id].convertRule
      this.convertAudit = this.convert[sourceItem.id + '->' + targetItem.id].convertAudit
      this.dialogVisible = true
      this.getFields()
    },
    confirmSettings () {
      // Logic to handle settings confirmation
      this.dialogVisible = false
    }
  }
}
</script>

<style>
#wrapper {
  display: flex;
  height: 100vh;
}

#componentLibrary {
  width: 200px;
  background: #e1e1e1;
}

.diagram-container {
  flex-grow: 1;
  background: #fff;
  position: relative;
}

.library-item {
  padding: 5px;
  margin: 10px;
  background: #f6f6f6;
  border: 2px solid #ddd;
  text-align: center;
  cursor: pointer;
}

.state-item {
  height: 80px;
  width: 80px;
  padding: 5px;
  background: #f6f6f6;
  border: 2px solid #ddd;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
}

.save-button {
  position: absolute;
  right: 40px;
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}
.back-button {
  position: absolute;
  left: 240px;
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}
</style>
