import request from '@/utils/request'

export function addFlow (data) {
  return request({
    url: '/flow/add',
    method: 'POST',
    data
  })
}
export function detail (data) {
  return request({
    url: '/flow/detail',
    method: 'POST',
    data
  })
}
export function getFlowList (data) {
  return request({
    url: '/flow/list',
    method: 'POST',
    data
  })
}
export function switchEnable (data) {
  return request({
    url: '/flow/switchEnable',
    method: 'POST',
    data
  })
}
export function deleteFlow (data) {
  return request({
    url: '/flow/delete',
    method: 'POST',
    data
  })
}
